<template>
    <setting-layout
        :title="$t('pages.user.setting.creator.subscriptions.title')"
    >
        <v-container class="pb-0 overflow-x-hidden">
            <v-row align="center" justify="center" no-gutters>
                <!-- subscription price -->
                <v-col class="relative" cols="12">
                    <div class="font-weight-bold text-md-body-2 pl-4 mb-2">
                        {{
                            $t(
                                "pages.user.setting.creator.subscriptions.subscription_price"
                            )
                        }}
                    </div>
                    <text-field
                        :error-messages="errors.price"
                        placeholder="4.99-49.99"
                        :prepend="currency"
                        v-on:keyup.enter="save"
                        v-model="model.price"
                    >
                        <template #append>
                            <div style="opacity: 0.5">
                                {{
                                    $t(
                                        "pages.user.setting.creator.subscriptions.per_month"
                                    )
                                }}
                            </div>
                        </template>
                    </text-field>
                </v-col>
            </v-row>

            <v-row align="center" justify="center" class="pa-4">
                <v-col cols="12">
                    <div class="font-weight-bold text-md-body-2 mb-2">
                        {{ $t("pages.user.setting.creator.subscriptions.subscription_default_message") }}
                    </div>
                </v-col>
                <v-col align="center" justify="center" cols="6 px-0 py-0">
                    <v-btn :class="{'active' : isText === true}" class="btn btn-primary text-white text" type="button" @click="welcomeSection('text')">
                        {{$t('pages.user.setting.creator.subscriptions.text_message')}}
                    </v-btn>
                </v-col>
                <v-col align="center" justify="center" cols="6 px-0 py-0">
                    <v-btn :class="{'active' : isAudio === true}" class="btn btn-primary text-white audio" type="button" @click="welcomeSection('audio')">
                        {{$t('pages.user.setting.creator.subscriptions.video_message')}}
                    </v-btn>
                </v-col>
            </v-row>

            <!-- welcome message -->
            <v-row v-if="isText" align="center" justify="center" class="pa-4">
                <v-col cols="12 px-0 py-0">
                    <v-textarea
                        color="rgba(237,237,237, .1)"
                        background-color="rgba(237,237,237, .1)"
                        no-resize
                        solo
                        :placeholder="
                            $t(
                                'pages.user.setting.creator.subscriptions.welcome_message'
                            )
                        "
                        rows="2"
                        :hint="`* ${$t(
                            'pages.user.setting.creator.subscriptions.welcome_message_rule'
                        )}`"
                        style="border-radius: 8px"
                        persistent-hint
                        :error-messages="errors.welcome_message"
                        v-on:keyup.enter="save"
                        v-model="model.welcome_message"
                    />
                </v-col>
                <v-col>
                    <v-switch v-model="isTextActive">
                        <template #prepend v-if="isText">
                            <v-label>{{ $t('pages.user.setting.creator.subscriptions.default_active_label') }}</v-label>
                        </template>
                    </v-switch>
                </v-col>
            </v-row>

            <v-row v-if="isAudio" class="pa-5 text-center audio-area flex-column">
                <template v-if="!model.welcome_audio">
                    <welcome-audio-recorder
                        class="mx-0"
                        :isRecordingDeleted="isRecordingDeleted"
                        @startRecordingAudio="startRecordingAudio"
                        @stopRecordingAudio="stopRecordingAudio"
                        @cancelRecordingAudio="cancelRecordingAudio"
                    />
                </template>
                <template v-else>
                    <wave-audio-player :audio-src="model.welcome_audio_url" @audioDelete="deleteAudio(model.id, model.welcome_audio, model.welcome_audio_url)" />
                </template>
                <span class="ml-4 my-2 text-left audio-hint">{{ $t('pages.user.setting.creator.subscriptions.welcome_audio_message_rule') }}</span>               
                <v-switch v-model="isAudioActive" class="px-4">
                    <template #prepend v-if="isAudio">
                        <v-label>{{ $t('pages.user.setting.creator.subscriptions.default_active_label') }}</v-label>
                    </template>
                </v-switch>
            </v-row>

            <!-- bundles -->
            <v-row align="center" class="mt-6 px-4" no-gutters>
                <v-row align="center" justify="center" class="mb-4" no-gutters>
                    <v-col cols="11">
                        <div
                            class="font-weight-bold text-md-body-2"
                            @click="showBundles = !showBundles"
                        >
                            Subscription bundles
                        </div>
                    </v-col>

                    <v-col cols="1">
                        <div
                            style="height: 24px; transition: 0.3s"
                            :style="showBundles ? 'transform: scaleY(-1);' : ''"
                        >
                            <IconMore />
                        </div>
                    </v-col>
                </v-row>

                <template v-if="showBundles">
                    <v-row
                        v-for="(bundle, bundleIndex) in model.bundles"
                        :key="bundle.period"
                        style="
                            background: rgba(237, 237, 237, 0.1);
                            border-radius: 8px;
                            line-height: 52px;
                        "
                        class="py-2 px-4 mt-2"
                    >
                        <div
                            style="
                                display: flex;
                                align-items: center;
                                width: 100%;
                            "
                        >
                            <v-checkbox
                                :value="bundle.isEnabled"
                                color="primary"
                                v-model="bundle.isEnabled"
                            />

                            <div class="text-no-wrap" style="width: 100%">
                                <b>{{ bundle.period }} months</b> for
                                <span class="price">
                                    {{ currency
                                    }}{{
                                        model.price * bundle.period -
                                        (model.price *
                                            bundle.period *
                                            bundle.discount) /
                                            100
                                    }}
                                </span>
                            </div>

                            <text-field
                                placeholder="0-50"
                                :error-messages="
                                    invalidBundleIndex === bundleIndex
                                        ? ['From 0 to 50 %']
                                        : []
                                "
                                type="number"
                                prepend="%"
                                style="
                                    max-width: 120px;
                                    min-width: 120px;
                                    width: 120px;
                                    background: transparent !important;
                                "
                                class="ml-2"
                                v-on:keyup.enter="save"
                                v-model="bundle.discount"
                            >
                                <template #append>
                                    <div style="opacity: 0.5">OFF</div>
                                </template>
                            </text-field>
                        </div>
                    </v-row>
                </template>
            </v-row>

            <!-- benefits list -->
            <v-row
                v-if="model.benefits_list"
                align="center"
                class="mt-6"
                no-gutters
            >
                <v-row
                    align="center"
                    justify="center"
                    class="mb-4 pr-2"
                    no-gutters
                >
                    <v-col cols="11">
                        <div class="font-weight-bold text-md-body-2 pl-4">
                            Subscription benefits
                        </div>
                    </v-col>

                    <v-col cols="1">
                        <button
                            v-if="
                                model.benefits_list.length < 10 &&
                                model.benefits_list.every(
                                    (str) => str.trim() !== ''
                                )
                            "
                            @click="model.benefits_list.push('')"
                        >
                            <app-icon icon="plus" size="16" />
                        </button>
                    </v-col>
                </v-row>

                <div
                    class="w-full mb-2 grey--text pl-4 delete-icon-background"
                    style="
                        background: rgba(237, 237, 237, 0.1);
                        border-radius: 8px;
                        line-height: 52px;
                    "
                >
                    Support the Noodzler
                </div>

                <div
                    class="w-full mb-2 grey--text pl-4 delete-icon-background"
                    style="
                        background: rgba(237, 237, 237, 0.1);
                        border-radius: 8px;
                        line-height: 52px;
                    "
                >
                    Unlock all the content
                </div>

                <div
                    class="w-full grey--text pl-4 delete-icon-background"
                    style="
                        background: rgba(237, 237, 237, 0.1);
                        border-radius: 8px;
                        line-height: 52px;
                    "
                >
                    Unlimited Direct Messages
                </div>

                <hr
                    v-if="model.benefits_list.length"
                    class="my-4 w-full"
                    style="opacity: 0.25; height: 1px"
                />

                <text-field
                    v-for="(item, index) in model.benefits_list"
                    :key="index"
                    v-on:keyup.enter="save"
                    v-model="model.benefits_list[index]"
                    class="mb-2"
                >
                    <template #append>
                        <button
                            style="opacity: 0.5"
                            @click="
                                model.benefits_list =
                                    model.benefits_list.filter(
                                        (benefit, benefitIndex) =>
                                            benefitIndex !== index
                                    )
                            "
                        >
                            <app-icon icon="delete" size="20" />
                        </button>
                    </template>
                </text-field>
            </v-row>

            <!-- save -->
            <v-row align="center" justify="center" class="pa-4 mt-2">
                <gradient-button
                    block
                    :loading="
                        $store.state.buttonsLoading[
                            'setting/subscriptionButton'
                        ]
                    "
                    @click="save"
                >
                    {{ $t("pages.user.setting.creator.subscriptions.save") }}
                </gradient-button>
            </v-row>
        </v-container>

        <!-- subscribers -->
        <v-container class="pt-2">
            {{ $t("pages.user.setting.creator.subscriptions.subscribers") }} ({{
                subscriberSubscriptions.length
            }})
            <v-row
                v-for="(subscription, i) in subscriberSubscriptions"
                :key="i"
                class="mt-3"
            >
                <div class="subscriber pl-4 pb-4">
                    <v-row class="subscriber-title">
                        <v-col cols="7" sm="4" md="6">
                            <router-link
                                :to="{
                                    name: 'otherUser.profile.viewSlug',
                                    params: {
                                        slug: subscription.subscriber.slug,
                                        from: $route.name
                                    },
                                }"
                            >
                                {{ subscription.subscriber.name }}
                            </router-link>
                        </v-col>
                        <v-col cols="5" sm="8" md="6" class="price d-flex justify-center"
                            >
                            {{ subscription.fixed_price }}
                            {{ currency }}
                        </v-col>
                    </v-row>

                    <v-row v-if="subscription.created_at">
                        <span class="date-text pl-4">{{ $t("pages.user.setting.creator.subscriptions.start_date") }}:</span>
                        <span class="date-value pl-3">
                            {{ subscription.created_at }}</span
                        >
                    </v-row>
                    <v-row v-if="subscription.renewal_at">
                        <span class="date-text pl-4">{{ $t("pages.user.setting.creator.subscriptions.renewal_date") }}:</span>
                        <span class="date-value pl-3">
                            {{ subscription.renewal_at }}
                        </span>
                    </v-row>
                    <v-row>
                        <span class="date-text pl-4">{{ $t("pages.user.setting.creator.subscriptions.total_earning") }}:</span>
                        <span class="date-value pl-3">{{ currency }} {{ subscription.total_earning }}</span>
                    </v-row>
                </div>
            </v-row>
        </v-container>

    </setting-layout>
</template>

<script>
import SettingLayout from "@/layouts/app/profile/SettingLayout";
import TextField from "@/components/app/form/TextField";
import GradientButton from "@/components/app/button/GradientButton";
import { mapState, mapActions, mapMutations } from "vuex";
import moment from "moment";
import { CURRENCY_SYMBOL } from "@/configs/constants";
import SetSubscriptionPriceError from "@/components/app/dialogs/SetSubscriptionPriceError.vue";
import IconMore from "@/assets/images/icons/more.svg?inline";
import WelcomeAudioRecorder from "@/components/app/common/WelcomeAudioRecorder";
import WaveAudioPlayer from "@/components/app/common/WaveAudioPlayer";

const defaultBundles = [
    {
        isEnabled: true,
        period: 3,
        discount: 15,
    },
    {
        isEnabled: true,
        period: 6,
        discount: 25,
    },
    {
        isEnabled: true,
        period: 12,
        discount: 50,
    },
];

export default {
    name: "Subscriptions",
    data() {
        return {
            errors: {},
            subscriberSubscriptions: [],
            currency: CURRENCY_SYMBOL,
            showBundles: false,
            isAudioRecording: false,
            isRecordingDeleted: false,
            isText: true,
            isAudio: false,
        };
    },
    computed: {
        ...mapState({
            model: (state) => {
                if (state.user.user.subscription) {
                    const result = state.user.user.subscription;

                    if (!result.bundles) {
                        result.bundles = defaultBundles;
                    }

                    return result;
                } else {
                    return {
                        price: "",
                        welcome_message: "",
                        benefits_list: [],
                        bundles: defaultBundles,
                        default_message: 0
                    };
                }
            },
        }),
        invalidBundleIndex() {
            return this.model.bundles.findIndex(
                (bundle) => bundle.discount < 0 || bundle.discount > 50
            );
        },
        isTextActive: {
            get() {
                return this.model.default_message === 1;
            },
            set(value) {
                this.model.default_message = value ? 1 : 0;
            }
        },
        isAudioActive: {
            get() {
                return this.model.default_message === 2;
            },
            set(value) {
                this.model.default_message = value ? 2 : 0;
            }
        },
    },
    methods: {
        ...mapActions([
            "sendSubscriptionData",
            "getSubscription",
            "uploadWelcomeAudioMessage",
            "removeWelcomeAudioMessage",
        ]),
        ...mapMutations(["showSnackBar"]),
        save() {
            if (this.invalidBundleIndex !== -1) return;

            this.model.benefits_list = this.model.benefits_list.filter(
                (item) => item.trim() !== ""
            );

            this.sendSubscriptionData({ model: this.model }).then(
                (res) => {
                    this.errors = {};
                    if (typeof res.message !== "undefined") {
                        this.showSnackBar(
                            this.$t(
                                "pages.user.setting.creator.subscriptions.saved"
                            )
                        );
                    }
                },
                (e) => (this.errors = e.response.data.errors)
            );
        },
        format(date) {
            return moment(date).format("DD MMM YYYY");
        },
        fetchSubscriberSubscriptions() {
            axios("api/subscribers/subscriptions", { hideLoader: true })
                .then(({ data: response }) => {
                    this.subscriberSubscriptions = response.data;
                })
                .catch(() => {
                    this.showSnackBar(this.$t("messages.something_went_wrong"));
                });
        },

        welcomeSection(section){
            if(section == 'text'){
                this.isText = true;
                this.isAudio = false;
            } else {
                this.isText = false;
                this.isAudio = true;
            }
        },

        async deleteAudio(subscriptionId, audioName, audioPath){
            await this.removeWelcomeAudioMessage({subscriptionId, audioName, audioPath})
            .then((res) => {
                if (typeof res.status !== "undefined") {
                    if(res.status){
                        this.model.welcome_audio = res.welcome_audio;
                        this.model.welcome_audio_url = res.welcome_audio_url;
                    }
                }
            });
        },

        async startRecordingAudio() {
            this.isAudioRecording = true;
            this.$core.storyCore.startRecordingAudio();
        },

        async stopRecordingAudio() {
            this.isAudioRecording = false;
            const audioBlob = await this.$core.storyCore.stopRecordingAudio();
            const creatorId = this.model.creator_id

            await this.uploadWelcomeAudioMessage({ audioBlob, creatorId: creatorId })
            .then((res) => {
                if (typeof res.status !== "undefined") {
                    if(res.status){
                        this.model.welcome_audio = res.welcome_audio;
                        this.model.welcome_audio_url = res.welcome_audio_url;
                    }
                }
            });
        },

        async cancelRecordingAudio() {
            this.isAudioRecording = false;
            this.isRecordingDeleted = true;
            await this.$core.storyCore.cancelRecordingAudio()
            .then(() => {
                setTimeout(() => {
                    this.isRecordingDeleted = false;
                }, 1000);
            });
        },
    },
    created() {
        this.fetchSubscriberSubscriptions();
    },
    async mounted() {
        await this.getSubscription();

        switch (this.model.default_message) {
            case 1:
                this.welcomeSection('text');
            case 2:
                this.welcomeSection('audio');
        }
    },

    components: {
        SetSubscriptionPriceError,
        GradientButton,
        TextField,
        SettingLayout,
        IconMore,
        WelcomeAudioRecorder,
        WaveAudioPlayer
    },
};
</script>

<style scoped>
.top-price {
    font-size: 0.9em;
}

.subscriber-title {
    font-size: 1.7em;
}

.subscriber-title .price {
    color: #38a209;
}

.subscriber {
    width: 100%;
    background: rgba(237, 237, 237, 0.1);
    border-radius: 8px;
}

.price {
    color: #fd6014;
}

.date-text {
    color: rgba(255, 255, 255, 0.5);
}

.audio-area .audio-hint {
    font-size: 0.75rem;
    color: rgba(255, 255, 255, 0.7);
    line-height: 0.75rem;
}

.audio, .text {
    background: rgba(237, 237, 237, 0.1) !important;
    box-shadow: 0px 3px 10px -2px rgba(85, 85, 85, 0.08), 0px 2px 20px 0px rgba(85, 85, 85, 0.06), 0px 1px 30px 0px rgba(85, 85, 85, 0.03) !important;
    transition: 0.3s;
}

.v-btn.active {
    background: linear-gradient(to bottom, #ffc107, #ffab40 15%, #ff9100 40%, #ff3d00 100%) !important;
    color: white;
}

</style>
