<template>
	<div class="voice-recorder">
		<v-row justify="center" class="voice-record-strip" align="center">
			<template v-if="isRecording">
				<v-col cols="4" class="d-flex align-center justify-center" align="center">
					<div class="timer">
						<img :src="require('@/assets/recording_animation.gif')" alt="Loading..." />
						{{ formattedTimer }}
					</div>
				</v-col>
				<v-col cols="6">
					<div class="slide-to-cancel">
						<v-icon size="22">mdi-chevron-left</v-icon>
						<span>{{ $t("messages.slide_to_cancel") }}</span>
					</div>
				</v-col>
			</template>

			<template v-else>
				<v-col cols="10">
					<span class="audio-text" style="font-size: 0.85rem;">
						{{ $t('pages.user.setting.creator.subscriptions.audio_note') }}
					</span>
				</v-col>
			</template>

			<v-col cols="2">
		 		<button
					class="record-button"
					:class="{'is-recording': isRecording}"
					@touchstart="startRecording"
					@touchend="stopRecording"
					@touchmove="handleTouchMove"
				>
					<span>
						<v-icon :size="micSize" :color="micColor">mdi-microphone-outline</v-icon>
					</span>
				</button>
			</v-col>
		</v-row>
  </div>
</template>

<script>
import { AUDIO_MESSAGE_LENGTH_TIME } from "@/configs/constants";
import EventBus from '../../../event-bus.js';
import moment from "moment";
import IconAudioWave from "@/assets/images/icons/audio-wave.svg?inline";

export default {
    name: "WelcomeAudioRecorder",
    data() {
    	return {
			isRecording: false,
			slideTextLeft: 0,
			startX: 0,
			recordingTimer: null,
			timer: 0,
			timerInterval: null,
      	};
    },
	props: {
        isRecordingDeleted: {
            type: Boolean,
            default: false
        }
    },
	computed: {
		formattedTimer() {
			return this.timer !== false ? moment.utc(this.timer * 1000).format('mm:ss') : '';
		},
		micColor() {
			return this.isRecording ? 'rgb(255,255,255)' : 'rgb(255,255,255)';
		},
		micSize() {
			return this.isRecording ? 36 : 32;
		},
	},
    methods: {
		startRecording(event) {
			this.slideTextLeft = 0,
			this.startX = event.type === 'mousedown' ? event.clientX : event.touches[0].clientX;
			this.recordingTimer = setTimeout(() => {
				this.isRecording = true;
				this.beginRecording();
			}, 800); // Small delay to prevent accidental taps
		},
		stopRecording() {
			if (this.isRecording) {
				clearTimeout(this.recordingTimer);
				this.isRecording = false;
				this.finishRecording();
			} else {
				clearTimeout(this.recordingTimer);
				clearInterval(this.timerInterval);
			}
		},
		cancelRecording() {
			if (this.isRecording) {
				clearTimeout(this.recordingTimer);
				this.isRecording = false;
				this.cancelRecordingAction();
			}
		},
		handleTouchMove(event) {
			if (this.isRecording) {
				const touchX = event.touches[0].clientX;
				this.slideTextLeft = touchX - this.startX;
		
				// Check if the user has slid left enough to cancel
				if (this.slideTextLeft < -100) { // Adjust threshold as needed
					this.cancelRecording();
				}
			}
		},
		beginRecording() {
			console.log('Recording started...');
			this.timer = 0
			this.$emit('startRecordingAudio');
		},
		finishRecording() {
			clearInterval(this.timerInterval);
			console.log('Recording stopped and saved.');
			this.$emit('stopRecordingAudio');
		},
		cancelRecordingAction() {
			clearInterval(this.timerInterval);
			console.log('Recording cancelled.');
			this.$emit('cancelRecordingAudio');
		},
		startTimer() {
			clearInterval(this.timerInterval);
			this.timerInterval = setInterval(() => {
				this.timer++;
				if (this.timer % AUDIO_MESSAGE_LENGTH_TIME === 0) {
					this.stopRecording()
				}
			}, 1000);
    	}
    },
	created() {
		// Listen for the 'recordingStarted' event from the parent
		EventBus.$on('audioRecordingStarted', this.startTimer);
	},
	beforeDestroy() {
		// Clean up interval and event listeners
		clearInterval(this.timerInterval);
		EventBus.$off('audioRecordingStarted', this.startTimer);
	},
	components: {
		IconAudioWave,
	},
};
</script>
<style scoped>
	.voice-record-strip { border-radius: 6px; background-color: rgba(237, 237, 237, 0.1); border-color: rgba(237, 237, 237, 0.1); box-shadow: 0px 3px 10px -2px rgba(85, 85, 85, 0.08), 0px 2px 20px 0px rgba(85, 85, 85, 0.06), 0px 1px 30px 0px rgba(85, 85, 85, 0.03); }

	.slide-to-cancel  {
		animation: animate 1.5s infinite alternate ease-in-out;
	}

	@keyframes animate {
    	0% {
        	transform: translateX(0px);
    	}
    	100% {
        	transform: translateX(-20px);
    	}
	}
</style>